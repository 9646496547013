import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { forwardRef } from "react";

const ReactHookFormNumberInput = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {(params: any) => void} onChange
   */
  /**
   * @param {import("@chakra-ui/react").NumberInputProps & Props} props
   */
  function ReactHookFormNumberInput(props, ref) {
    const { name, onChange } = props;

    return (
      <NumberInput
        {...props}
        ref={ref}
        onChange={function (_, valueAsNumber) {
          onChange({
            target: {
              name,
              value: !isNaN(valueAsNumber) ? valueAsNumber : 0,
            },
          });
        }}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    );
  },
);

export default ReactHookFormNumberInput;
