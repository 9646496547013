import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Collapse,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useApiError } from "../../containers/ApiError";

/**
 * @param {import("@chakra-ui/react").BoxProps & {
 * withCaughtErrors?: boolean;
 * }} props
 */
function ApiErrorMessage({ withCaughtErrors = false, ...otherProps }) {
  const { message, caughtErrors, uncaughtErrors } = useApiError();

  const uncaughtErrorsList = useMemo(() => {
    return Object.values(uncaughtErrors);
  }, [uncaughtErrors]);

  const caughtErrorsList = useMemo(() => {
    return Object.values(caughtErrors);
  }, [caughtErrors]);

  return (
    <Collapse in={Boolean(message)} unmountOnExit={true}>
      <Box pb="1rem" {...otherProps}>
        <Alert status="error" variant="top-accent">
          <AlertTitle display="block">{message}</AlertTitle>
        </Alert>

        {uncaughtErrorsList?.length > 0 && (
          <Alert status="error">
            <AlertDescription>
              <UnorderedList display="block" opacity={0.75}>
                {uncaughtErrorsList?.map?.((value, index) => (
                  <ListItem key={index}>{value}</ListItem>
                ))}
              </UnorderedList>
            </AlertDescription>
          </Alert>
        )}

        {withCaughtErrors && caughtErrorsList?.length > 0 && (
          <Alert status="error">
            <AlertDescription>
              <UnorderedList display="block" opacity={0.75}>
                {caughtErrorsList?.map?.((value, index) => (
                  <ListItem key={index}>{value}</ListItem>
                ))}
              </UnorderedList>
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Collapse>
  );
}

export default ApiErrorMessage;
