import resolveApiError from "@raiden/library-ui/libraries/utils/reactHookForm/resolveApiError";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

/**
 * @typedef {{
 * message: string | null,
 * setMessage: (message: string | null) => void,
 * caughtErrors: {[key:string]: string},
 * setCaughtErrors: (errors: {[key:string]: string}) => void,
 * uncaughtErrors: {[key:string]: string},
 * setUncaughtErrors: (uncaughtErrors: {[key:string]: string}) => void,
 * resolveApiError: (params: { response: any, form: any }) => void,
 * resetApiErrors: () => void,
 * }} FormErrorContextValue
 */

/**
 * @type {FormErrorContextValue}
 * */
const defaultContextValue = {
  message: null,
  setMessage: () => {},
  caughtErrors: {},
  setCaughtErrors: () => {},
  uncaughtErrors: {},
  setUncaughtErrors: () => {},
  resolveApiError: () => {
    return {};
  },
  resetApiErrors: () => {},
};

const ApiErrorContext = createContext(defaultContextValue);

/**
 * @param {{
 * children: React.ReactNode,
 * }} param0
 * @returns
 */
export function ApiErrorProvider({ children }) {
  /** @type {[string | null, React.Dispatch<React.SetStateAction<string | null>>]} */
  const [message, setMessage] = useState(null);
  const [uncaughtErrors, setUncaughtErrors] = useState({});
  const [caughtErrors, setCaughtErrors] = useState({});

  const _resolveApiError = useCallback(({ response, form }) => {
    const result = resolveApiError({
      response,
      form,
      setMessage,
      setCaughtErrors,
      setUncaughtErrors,
    });
    return result;
  }, []);

  const resetApiErrors = useCallback(() => {
    setMessage(null);
    setCaughtErrors({});
    setUncaughtErrors({});
  }, []);

  /** @type {FormErrorContextValue} */
  const contextValue = useMemo(() => {
    return {
      message,
      setMessage,
      uncaughtErrors,
      setUncaughtErrors,
      caughtErrors,
      setCaughtErrors,
      resolveApiError: _resolveApiError,
      resetApiErrors,
    };
  }, [_resolveApiError, caughtErrors, message, resetApiErrors, uncaughtErrors]);

  return (
    <ApiErrorContext.Provider value={contextValue}>
      {children}
    </ApiErrorContext.Provider>
  );
}

/**
 * @template P
 * @deprecated prefer using ApiErrorProvider
 * @param {React.FunctionComponent<P>} Component
 * @returns {React.FunctionComponent<P>}
 */
export function withApiError(Component) {
  return ({ ...otherProps }) => {
    return (
      <ApiErrorProvider>
        <Component {...otherProps} />
      </ApiErrorProvider>
    );
  };
}

export function useApiError() {
  const contextValue = useContext(ApiErrorContext);
  return contextValue;
}

export default ApiErrorContext;
